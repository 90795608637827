import request from '@/plugins/axios';

/**
 * 合同详情
 */
export function getContractInfo(params = {}) {
  return request({
    url: `school-mobile/hContractInfo/${params.contractId}`,
    method: 'get',
    params,
  });
}

/**
 * 签合同
 */
export function signContract(data = {}) {
  return request({
    url: `school-mobile/hContractInfo/sign`,
    method: 'put',
    data,
  });
}

/**
 * 合同列表
 */
export function getUserContractList(params = {}) {
  return request({
    url: `school-mobile/hContractInfo/getUserContractList`,
    method: 'get',
    params,
  });
}
