<template>
  <page-container>
    <van-cell
        v-for="data in dataList"
        :key="data.id"
        :title="data.contractTitle"
        :label="`签订时间: ${data.signTime}`"
        center
        @click="detail(data)"
    >
      <template #right-icon>
        <van-icon name="eye-o"/>
      </template>
    </van-cell>
    <van-empty v-if="isEmpty" description="暂无数据"/>
  </page-container>
</template>

<script>
import { getUserContractList } from '@/api/agreement';
import { Dialog } from 'vant';

export default {
  name: 'agreementList',
  data() {
    return {
      dataList: [],
      isEmpty: false,
    };
  },
  created() {
    getUserContractList().then(res => {
      this.dataList = Array.isArray(res) ? res : [];
      this.isEmpty = !this.dataList.length;
    });
  },
  methods: {
    detail(data) {
      Dialog.alert({
        message: data.content,
        className: 'contract-container',
        confirmButtonText: '关闭',
      });
      window.setTimeout(() => {
        this.$nextTick(() => {
          document.getElementsByClassName('contract-container')[0].querySelectorAll('.van-dialog__message')[0].scrollTop = 0;
        });
      });
    },
  },
};
</script>

<style lang="less">
.contract-container {
  .van-dialog__message {
    padding: 10px 14px 25px 14px;
    text-align: left;

    .ql-align-center {
      text-align: center;
    }
  }
}
</style>
